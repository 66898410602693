<template>
  <section class="list">
    <div v-for="(book, i) in books" :key="i" class="book">
      <router-link :to="{ name: 'book', params: { id: book.id } }" class="book__img">
        <img :src="$store.state._env.MEDIA_ENDPOINT + book.img.path" :alt="book.img.alt" />
        <img :src="$store.state._env.MEDIA_ENDPOINT + book.img.path" :alt="book.img.alt" />
      </router-link>
      <div class="book__body">
        <div class="book__header">
          <router-link :to="{ name: 'book', params: { id: book.id } }" class="book__title">
            {{ book.title }}
          </router-link>
          <router-link
            :to="{ name: 'book', params: { id: book.id } }"
            class="book__subtitle"
            v-if="book.author"
          >
            {{ book.author.title }}
          </router-link>
        </div>
        <div class="book__footer">
          <div class="book__price">
            <span class="book__discount" v-if="book.share && book.share.percent">
              {{ (book.price / 100) * book.share.percent }} ₽
            </span>
            <span>{{ book.price }} ₽</span>
          </div>
          <a
            :href="`mylibstore://mylib.store/book/${book.id}`"
            class="btn btn--sm btn--main"
            v-if="user_books.includes(book.id)"
            @click.prevent="buy(book.id)"
          >
            <IconComponent name="book" category="outline" />
            <span>Читать в приложении</span>
          </a>
          <button
            type="button"
            class="btn btn--sm"
            :class="{
              'btn--green': cart.includes(book.id),
              'btn--main': !cart.includes(book.id),
            }"
            v-else
            @click.prevent="buy(book.id)"
          >
            <IconComponent name="loading" category="default" v-if="loading === book.id" />
            <IconComponent name="shopping-cart" category="outline" v-else />
            <span v-if="cart.includes(book.id)">В корзине</span>
            <span v-else>В корзину</span>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LoginModal from "components/modals/components/LoginModal.vue";
import IconComponent from "components/IconComponent.vue";

export default {
  components: { IconComponent },
  data: () => ({
    loading: false,
  }),
  async asyncData({ store, apollo }) {
    let queries = [store.dispatch("books", { apollo: apollo.defaultClient })];
    if (store.state.apollo_token && store.state.user) {
      queries.push(store.dispatch("cart", { apollo: apollo.defaultClient }));
      queries.push(store.dispatch("user_books", { apollo: apollo.defaultClient }));
    }
    await Promise.all(queries);
  },
  computed: {
    cart() {
      return this.$store.state.cart.map((c) => c.id);
    },
    user_books() {
      return this.$store.state.user_books;
    },
    books() {
      return this.$store.state.books;
    },
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    buy(id) {
      if (!this.user) {
        this.$store.state._modals.push({
          component: LoginModal,
        });
        return;
      }
      if (!this.loading) {
        this.loading = id;
        this.$store
          .dispatch("toggle_cart", { apollo: this.$apollo, id })
          .then(() => {
            this.$store.dispatch("cart", { apollo: this.$apollo });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  name: "HomePage",
};
</script>

<style lang="stylus">
.list {
  padding 30px
  display grid
  grid-gap 30px
  grid-template-columns repeat(5, 1fr)
  +below(1440px) {
    grid-template-columns repeat(4, 1fr)
  }
  +below(1240px) {
    grid-template-columns repeat(3, 1fr)
  }
  +below(1024px) {
    grid-template-columns repeat(2, 1fr)
  }
  +below(560px) {
    padding 16px
    grid-template-columns 1fr
  }

  a {
    text-decoration none
  }
}

.book {
  display grid
  height 100%
  width 100%
  background var(--white)
  border-radius 10px
  border 1px solid var(--main-light)
  box-shadow 0 5px 10px rgba(153, 108, 61, 0.2)
  grid-template-rows 370px 1fr
  +below(1600px) {
    grid-template-rows 270px 1fr
  }
  +below(1440px) {
    grid-template-rows 270px 1fr
  }
  +below(640px) {
    grid-template-rows 200px 1fr
  }

  &__img {
    border-radius 10px
    width 100%
    max-height 370px
    position relative
    overflow hidden
    +below(1600px) {
      max-height 270px
    }
    +below(640px) {
      max-height 200px
    }

    img:first-child {
      object-fit cover
      object-position center
      width 100%
      height 100%
      absolute left top
      filter blur(10px)
    }

    img:last-child {
      object-fit contain
      object-position center
      width 100%
      height 100%
      position relative
      z-index 1
    }
  }

  &__body {
    display grid
    grid-template-rows 1fr auto
    height 100%
    padding 16px
    gap 16px
  }

  &__header {
    display grid
    align-items start
    align-content start
    gap 8px
  }

  &__title {
    font-weight: 700;
    font-size: 1.25em;
    line-height: 140%;
    color: var(--dark);
  }

  &__subtitle {
    font-weight: 300;
    font-size: 1em;
    line-height: 140%;
    color: var(--dark);
  }

  &__footer {
    display flex
    align-items center
    justify-content space-between
  }

  &__price {
    display grid
    font-weight: 700;
    font-size: 1.25em;
    line-height: 140%;
    color: var(--dark);
  }

  &__discount {
    font-size 0.875em
    font-weight 400
    text-decoration line-through
  }
}
</style>
